import React from 'react'

function About() {
    return (
        <div className="about-page">
           <h4>A Simple Online Shopping Cart built with React Hooks and Context API</h4>
        </div>
    )
}

export default About
