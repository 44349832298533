import React from 'react'
import ProductGrid from '../components/Product/ProductGrid'

function Store() {
    return (
        <>
            <ProductGrid />
        </>
    )
}

export default Store
